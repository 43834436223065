import { config } from '@encompaas/common';
import { useMsalLogout } from '@encompaas/common/auth';
import { AppBarWithActions, Box, Page } from '@encompaas/common/components';
import { useUser } from '@encompaas/common/hooks';
import { TEST_CONSTANTS } from '@encompaas/common/constants';

const PageWithAppBar = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  const onLogout = useMsalLogout();
  const user = useUser();
  const handleHelpOption = (option:string) => {
    switch (option) {
      case 'Help':
        //Clicking 'Help' action opens the EncompaaS Help webpage in another Browser tab
        window.open('https://encompaas.zendesk.com/hc/en-us', '_blank'); 
        break;

      case 'Community':
        //handle 'Community' action logic
        //...
        break;

      case 'Contact us':
        //handle 'Contact us' action logic
        //...
        break;

      case 'About':
        //handle 'About' action logic
        //... 
        break;
        
      default:
        //Handle additional action logic, if required.
        //...
        break;
    }
  }

  return (
    <Page {...props} background='default' backgroundImage={true}>
      <AppBarWithActions
        user={user}
        color='secondary'
        title={config.TITLE}
        data-testid={TEST_CONSTANTS.APP_BAR}
        onLogout={onLogout}
        onHelpOption={handleHelpOption}
        helpOptions={[
          'Help',
          'Community',
          'Contact us',
          'About',
          `${config.TITLE} v${config.VERSION}`,
        ]}
      />
      <Box
        background='none'
        padding='large'
        style={{ paddingBottom: 0, paddingTop: 0, overflow: 'auto' }}
      >
        {children}
      </Box>
    </Page>
  );
};

export default PageWithAppBar;
