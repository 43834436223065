import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensors,
  useSensor,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useState } from 'react';
import { Box } from './Box';
import {
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { ChevronDown20Regular, Person16Filled } from '@fluentui/react-icons';
import { Approver, ApproversList } from '../types';
import { Button } from './Button';
import { SortableItem } from './list/SortableItem';

export const EditApprovers = ({
  approvers,
  selectedApprovers,
  setSelectedApprovers,
  isApproversListValid,
  setIsApproversListValid,
}: {
  approvers: ApproversList;
  selectedApprovers?: Approver[];
  setSelectedApprovers?: any;
  isApproversListValid?: boolean;
  setIsApproversListValid?: any;
}) => {
  const [localApprovers, setLocalApprovers] = useState(approvers.value);

  // TODO: enable filtering approvers search
  const [approversSelectListHidden, setApproversSelectListHidden] =
    useState(true);

  const theme = useTheme();

  const selectButtonBorderColor = isApproversListValid
    ? theme.palette.info[600]
    : theme.palette.warning.main;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 150, tolerance: 5 },
    })
  );

  const handleOnDelete = (index: number, approver: Approver) => {
    var array = [...selectedApprovers];
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedApprovers(array);
      setLocalApprovers([...localApprovers, approver]);

      if (array.length == 0) {
        setIsApproversListValid(false);
      }
    }
  };

  const handleSort = (index: number, approver: Approver) => {
    var array = [...localApprovers];
    if (index !== -1) {
      array.splice(index, 1);
      setLocalApprovers(array);
      setSelectedApprovers([...selectedApprovers, approver]);
      setIsApproversListValid(true);
    }
  };

  return (
    <Box width='25rem' background='none'>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <List
          dense
          sx={{
            width: '25rem',
            borderRadius: '0.5rem',
            border: `1px solid ${theme.palette.background.paper}`,
            padding: 0,
            overflow: 'hidden',
          }}
        >
          <SortableContext items={selectedApprovers.map((item) => item.ID)}>
            {selectedApprovers?.map((approver, index) => (
              <SortableItem
                key={approver.ID}
                index={index}
                id={approver.ID}
                name={approver._Display}
                onDelete={() => handleOnDelete(index, approver)}
              />
            ))}
          </SortableContext>
        </List>
      </DndContext>

      <Box background='none'>
        <Button
          color='info'
          variant='outlined'
          endIcon={<ChevronDown20Regular color={theme.palette.primary.main} />}
          sx={{
            fontFamily: 'Avenir Roman',
            justifyContent: 'space-between',
            borderColor: selectButtonBorderColor,
            width: '25rem',
          }}
          onClick={() => {
            setApproversSelectListHidden(!approversSelectListHidden);
          }}
        >
          - Add an approver -
        </Button>
        <Box
          background='none'
          style={{
            borderRadius: '0.5rem',
            border: `1px solid ${theme.palette.info[600]}`,
            display: approversSelectListHidden ? 'none' : '',
            position: 'absolute',
            background: 'white',
            marginTop: '2.813rem',
            boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
          }}
        >
          <TextField
            size='small'
            sx={{
              margin: '0.5rem',
              padding: '5px !important',
            }}
            onChange={(event) => {
              console.log(event.target.value);
              //TODO: enable filtering approvers search
            }}
          />
          <List
            sx={{
              width: '25rem',
              borderRadius: '0.5rem',
              padding: 0,
              overflow: 'scroll',
              maxHeight: '10rem',
            }}
          >
            {localApprovers.map((approver, index) => (
              <ListItem disablePadding key={approver.ID}>
                <ListItemButton onClick={() => handleSort(index, approver)}>
                  <Person16Filled />
                  <Typography variant='body2' ml={1}>
                    {approver._Display}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );

  function handleDragEnd({ active, over }) {
    console.log('ACTIVE: ' + active.id);
    console.log('OVER :' + over.id);

    if (active.id !== over.id) {
      setSelectedApprovers((selectedApprovers) => {
        const oldIndex = selectedApprovers.findIndex(
          (item) => item.ID === active.id
        );
        const newIndex = selectedApprovers.findIndex(
          (item) => item.ID === over.id
        );

        return arrayMove(selectedApprovers, oldIndex, newIndex);
      });
    }
  }
};
