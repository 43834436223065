import {
  Box,
  DisposalApprovalDialog,
  DisposalRequestDetailsPanel,
  DisposalRequestWarningAlert,
  DisposalRequestSuccessAlert,
  DisposalRequestHeaderActionButtons,
  HomeButton,
  ItemsGrid,
  TabPanel,
} from '@encompaas/common/components';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useDisposalRequest } from '@encompaas/common/hooks';
import { DisposalRequest64 } from '@encompaas/common/icons';
import {
  useGetApproversListQuery,
  useUpdateDisposalRequestMutation,
} from '@encompaas/common/services';
import { Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';

import {
  Approver,
  DisposalRequestResponseStatus,
  DisposalRequestStatus,
} from '@encompaas/common/types';

const DisposalRequestPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [queryParams, setQueryParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState('details');

  const [approvalErrorMessage, setApprovalErrorMessage] = useState('');

  const [successAlertOpen, setSuccessAlertOpen] = useState<boolean>(false);
  const [warningAlertOpen, setWarningAlertOpen] = useState<boolean>(false);

  const [disposalRequestApprove] = useUpdateDisposalRequestMutation();

  const [disposalApprovalDialogOpen, setDisposalApprovalDialogOpen] =
    useState(false);

  useEffect(() => {
    const _selectedTab = queryParams.get('tab') ?? 'details';
    if (_selectedTab !== selectedTab) {
      setSelectedTab(_selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const { data, isSuccess } = useDisposalRequest({ id });

  const { data: approvers } = useGetApproversListQuery();

  const handleHome = () => {
    //if history param is set then we can go back, otherwise go home
    !!queryParams.get('history') ? navigate(-1) : navigate('/');
  };

  const handleSelectTab = (tab: string) => {
    //this will reset the history param so that the home button will go home rather than back
    setQueryParams({ tab });
  };

  const handleSuccessAlertClose = () => {
    setSuccessAlertOpen(false);
  };
  const handleWarningAlertClose = () => {
    setWarningAlertOpen(false);
  };

  const handleApproveAction = async (
    comment: string,
    approvers: Approver[]
  ) => {
    // TODO: create type for api approversList when update the query
    const approversList: {
      Approver: { ID: string };
      Status: string;
      Order: number;
    }[] = approvers.map((approver, index) => {
      if (index === 0) {
        return {
          Approver: { ID: approver.ID },
          Status: DisposalRequestResponseStatus.Pending,
          Order: index + 1,
        };
      } else {
        return {
          Approver: { ID: approver.ID },
          Status: DisposalRequestResponseStatus.Waiting,
          Order: index + 1,
        };
      }
    });

    try {
      const response = await disposalRequestApprove({
        comment: comment,
        requestId: id,
        approversList: approversList,
        requestedBy: data?.RequestedBy?.ID,
      });

      if ('error' in response) {
        const _response = response as {
          error: {
            data: {
              FriendlyMessage: string;
            };
          };
        };
        setApprovalErrorMessage(_response.error.data.FriendlyMessage);
        setWarningAlertOpen(true);
      } else {
        setSuccessAlertOpen(true);
      }
    } catch (err) {
      setWarningAlertOpen(true);
    } finally {
      setDisposalApprovalDialogOpen(false);
    }
  };

  const handleSelectItem = (target?: string) =>
    navigate(`/item/${target}?requestId=${id}`);

  const Grid = useMemo(
    () => <ItemsGrid onOpen={handleSelectItem} disposalId={id!} adminMode />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.REQUEST_PAGE}>
      <DisposalRequestWarningAlert
        onClose={handleWarningAlertClose}
        open={warningAlertOpen}
        message={approvalErrorMessage}
      />
      <DisposalRequestSuccessAlert
        onClose={handleSuccessAlertClose}
        open={successAlertOpen}
      />

      <DisposalApprovalDialog
        open={disposalApprovalDialogOpen}
        title='Approve Disposal Request?'
        disposalRequest={data}
        approvers={approvers}
        onClose={() => {
          setDisposalApprovalDialogOpen(false);
        }}
        onAction={handleApproveAction}
      />

      <Box background='light' rounded='top' blur>
        <Box background='none' height={3.25} justifyContent='center'>
          <HomeButton
            label='Home'
            onClick={() => handleHome()}
            style={{ marginLeft: '1.5rem', alignSelf: 'start' }}
            data-testid={TEST_CONSTANTS.HOME_BUTTON}
          />
        </Box>
        <Divider style={{ width: '100%' }} />
        <Box
          background='none'
          padding='large'
          direction='row'
          gap='large'
          height={4}
        >
          <Box shrink>
            <DisposalRequest64 />
          </Box>
          <Box background='none' justifyContent='center' gap='xsmall'>
            <Typography variant='h1'>{data?.Name ?? 'Request'}</Typography>
            <Typography variant='h4'>Disposal request</Typography>
          </Box>

          <DisposalRequestHeaderActionButtons
            status={data?.Status as DisposalRequestStatus}
            onChangeApprovers={() => {
              console.log('onChangeApprovers called');
              setDisposalApprovalDialogOpen(true);
            }}
            onCloseRequest={() => {
              console.log('onCloseRequest called');
            }}
            onRequestApproval={() => {
              setDisposalApprovalDialogOpen(true);
            }}
            onDestroyItems={() => {
              console.log('onDestroyItems called');
            }}
            onReapprove={() => {
              console.log('onReapprove called');
            }}
          />
        </Box>

        <TabPanel
          selected={selectedTab}
          onSelect={handleSelectTab}
          inset={'7rem'}
          tabs={[
            {
              title: 'Details',
              value: 'details',
              children: isSuccess && !!data && (
                <DisposalRequestDetailsPanel request={data} adminMode={true} />
              ),
            },
            {
              title: 'Items',
              value: 'items',
              children: (
                <Box
                  padding='large'
                  background='none'
                  style={{ paddingLeft: '7rem' }}
                >
                  {Grid}
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </PageWithAppBar>
  );
};

export default DisposalRequestPage;
