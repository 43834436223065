import { Copy20Regular, PeopleTeam20Regular } from '@fluentui/react-icons';
import { ApproversList, DisposalRequest, Approver } from '../../types';
import CustomActionModal from './CustomActionModal';
import { Button } from '../Button';
import { DetailsRow } from '../DetailsRow';
import { Divider, Link, TextField, Typography, useTheme } from '@mui/material';
import { EditApprovers } from '../EditApprovers';
import { useState } from 'react';

export type DisposalApprovalDialogProps = {
  title?: string;
  disposalRequest?: DisposalRequest | undefined;
  approvers?: ApproversList | undefined;
  open: boolean;
  onClose?: () => void;
  onAction?: (actionButtonTitle: string, approvers: Approver[]) => void;
};
export const DisposalApprovalDialog = ({
  title = 'Request Disposal Approval',
  disposalRequest,
  approvers,
  open,
  onClose,
  onAction,
}: DisposalApprovalDialogProps) => {
  const theme = useTheme();
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [comment, setComment] = useState('');

  const [isApproversListValid, setIsApproversListValid] = useState(true);

  return (
    <CustomActionModal
      title={title}
      onClose={onClose}
      open={open}
      icon={<PeopleTeam20Regular style={{ marginRight: '0.75rem' }} />}
      actionButton={
        <Button
          variant='contained'
          onClick={() => {
            if (selectedApprovers.length > 0) {
              onAction(comment, selectedApprovers);
            } else {
              setIsApproversListValid(false);
            }
          }}
        >
          Request Approval
        </Button>
      }
    >
      <DetailsRow label='Name' data={disposalRequest?.Name} size='small' />
      <DetailsRow
        label='Disposal Action'
        data={disposalRequest?.DisposalAction}
        size='small'
      />
      {/*  TODO:  replace hardcoded url with .env variable */}
      <DetailsRow
        label='Web Link'
        data={
          <Link
            variant='body2'
            href={`https://encompaas.cloud/request/${disposalRequest?.ID}`}
          >
            https://encompaas.cloud/request/{disposalRequest?.ID}
            <Copy20Regular color={theme.palette.primary.main} />
          </Link>
        }
        size='small'
      />
      <Divider
        sx={{
          margin: '1.5rem 0',
        }}
      />

      <EditApprovers
        approvers={approvers}
        setSelectedApprovers={setSelectedApprovers}
        selectedApprovers={selectedApprovers}
        isApproversListValid={isApproversListValid}
        setIsApproversListValid={setIsApproversListValid}
      />

      <Typography
        variant='body1'
        mt={4}
        sx={{
          color: theme.palette.info.dark,
        }}
      >
        Comment
      </Typography>
      <TextField
        sx={{
          marginTop: '0.5rem',
        }}
        fullWidth
        maxRows={5}
        minRows={5}
        multiline
        onChange={(event) => setComment(event.target.value)}
        value={comment}
      />
    </CustomActionModal>
  );
};
